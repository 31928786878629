import React from "react"
import { Link } from "gatsby"

import { createUseStyles } from "react-jss"
import clsx from "clsx"

const useStyles = createUseStyles(theme => ({
  nav: {
    gridColumnStart: 2,
    gridColumnEnd: 5,
    gridRowStart: 3,
    gridRowEnd: 4,
    display: "flex",
    flexDirection: "column",
    "@media (min-width: 700px)": {
      flexDirection: "initial",
    },
  },
  navItem: {
    flex: 1,
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    "@media (min-width: 700px)": {
      padding: "2rem",
    },
  },
  txt: {
    color: "#fff",
    fontSize: "3vh",
    position: "relative",
    textAlign: "center",
    zIndex: 3,
    letterSpacing: ".2rem",
    transition: "all .3s",
    pointerEvents: "none",
  },
  link: {
    padding: "1rem",
    position: "relative",
    textDecoration: "none",
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "all .4s",
    borderRadius: "1.5vh",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      borderRadius: "1.5vh",
      border: "2px solid rgba(255,255,255, .8)",
      background: "rgba(0,0,0,.8)",
      transition: "all .4s",
    },
    "&:hover": {
      transform: "scale(1.2)",
      zIndex: 3,
      boxShadow: "0px 0px 2px 3px rgba(0, 0, 0, 0.86)",
      "&::before": {
        "@media (min-width: 700px)": {
          borderColor: "transparent",
          background: "rgba(0,0,0,.0)",
        },
      },
    },
    "&:hover .inner-txt": {
      "@media (min-width: 700px)": {
        transform: "translateY(150%) scale(0.7, 0.7)",
      },
    },
  },
}))

type NavItemPropBase = { to: string; txt: string }
type NavItemProp = NavItemPropBase & { img: any }

const NavItem = (props: NavItemProp) => {
  const { src } = props.img?.image?.childImageSharp?.fluid
  const c = useStyles()
  return (
    <div className={c.navItem}>
      <Link
        className={c.link}
        to={props.to}
        style={{ backgroundImage: `url(${src})` }}
      >
        <p className={clsx([c.txt, "inner-txt"])}>{props.txt}</p>
      </Link>
    </div>
  )
}
const NavItemContact = (props: NavItemProp) => {
  const c = useStyles()
  const { src } = props.img?.image?.childImageSharp?.fluid
  return (
    <div className={c.navItem}>
      <a
        className={c.link}
        href={props.to}
        style={{ backgroundImage: `url(${src})` }}
      >
        <p className={clsx([c.txt, "inner-txt"])}>{props.txt}</p>
      </a>
    </div>
  )
}

const navItems: Array<NavItemPropBase> = [
  { txt: "photos", to: "/Photos" },
  { txt: "video", to: "/Video" },
]

const navItemContactPayload = {
  txt: "contact",
  to: "mailto:info@villavernazza.com?subject=Website contact",
}

const Nav = (props: any) => {
  const c = useStyles()

  const imgMail = props.data.allPhotosJson.nodes.find((_, i) => i === 2)

  return (
    <nav className={c.nav}>
      {navItems.map((item, i) => {
        const img = props.data.allPhotosJson.nodes.find((_, ii) => i === ii)
        return <NavItem key={item.txt} img={img} {...item} />
      })}

      <NavItemContact img={imgMail} {...navItemContactPayload} />
    </nav>
  )
}

export default Nav
