import React from "react"
import clsx from "clsx"

import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  root: {
    gridColumnStart: 2,
    gridColumnEnd: 5,
    gridRowStart: 2,
    gridRowEnd: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "3vh",
    flexDirection: "column",
    lineHeight: "2.6rem",
  },
  txtCenter: {
    textAlign: "center",
  },
  strong: {
    lineHeight: "initial",
    fontFamily: "var(--mont-font), sans-serif",
    fontSize: "80%",
    letterSpacing: ".1rem",
    marginTop: 32,
  },
  "@media (min-width: 700px)": {
    root: {
      lineHeight: "initial",
      fontSize: "calc(16px + 32 * ((100vw - 320px) / 680))",
    },

    strong: { marginTop: 0 },
  },
}))

const Header = () => {
  const c = useStyles()
  return (
    <header className={c.root}>
      <h1 className={c.txtCenter}>Villa Vernazza</h1>
      <strong className={clsx(c.txtCenter, c.strong)}>
        The holiday villa you've been dreaming about
      </strong>
    </header>
  )
}

export default Header
