import React from "react"
import { createUseStyles } from "react-jss"
import fallbackImg from "../../data/photoImages/attic_bedroom.jpg"

const useStyles = createUseStyles({
  main: {
    height: "100vh",
    display: "flex",
    flex: 1,
    overflow: "hidden",
    position: "relative",
    boxShadow: "inset -10px -4px 80px 35px rgba(50, 50, 50, 0.86)",
    "&::after": {
      background: "rgba(0, 0, 0, .55)",
      position: "absolute",
      content: '""',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
  },
  video: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    minWidth: "100%",
    minHeight: "100%",
    width: "auto",
    height: "auto",
    zIndex: "-1000",
    overflow: "hidden",
  },
  overlay: {},
})

const Main = () => {
  const c = useStyles()
  return (
    <div className={c.main}>
      <video
        className={c.video}
        autoPlay="autoplay"
        loop
        muted
        src="https://staticvvernazza.blob.core.windows.net/static/villa-vernazza.mp4"
        poster={fallbackImg}
      ></video>
    </div>
  )
}

export default Main
