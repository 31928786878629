import React from 'react'
import { createUseStyles } from 'react-jss'

import Main from '../components/Main'
import VideoBg from '../components/VideoBg'

const useStyles = createUseStyles({
  container: {
    height: '100vh',
    overflowY: 'hidden',
    display: 'flex',
  }
})


const Home = () => {
  const c = useStyles()
  return (
    <div className={c.container}>
      <VideoBg />
      <Main />
    </div>
  )
}

export default Home
