import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Nav from './Nav'
import Header from './Header'

import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  main: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 2fr 2fr 1fr',
    gridTemplateRows: 'repeat(4, 1fr)'
  }
}))

const Main = () => {
  const c = useStyles()
  return (
    <StaticQuery
      query={graphql`
        query {
          allPhotosJson(
            filter: { slug: { in: ["second-bedroom", "attic-bedroom", "master-bedroom"] } }
          ) {
            nodes {
              id
              slug
              image {
                childImageSharp {
                  fluid{
                    src
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <main className={c.main}>
            <Header />
            <Nav data={data} />
          </main>
        )
      }}
    />
  )
}


export default Main
